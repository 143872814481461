import React, { useState } from "react";
import {
  Grid,
  Box,
  Divider,
  Typography,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import { Sidebar, CenteredModeButtons } from "./ChatsViewStyles";
import { hasPrivilege } from "../../utils/AuthUtils";

const ChatInformation = ({
  isRightSidebarOpen,
  chatMode,
  selectedChat,
  loadingChat,
  closingChat,
  renderMetadataTable,
  handleCloseChatConfirm,
  rightSidebarSize,
  rightSidebarRef,
  toggleRightSidebar,
  handleChangeMode,
  isCloseDialogOpen,
  setIsCloseDialogOpen,
  isRestartDialogOpen,
  setIsRestartDialogOpen,
  handlRestartChatConfirm,
}) => {
  const { t } = useTranslation("chats");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const processDescription = (description, t) => {
    return description.replace(/<([^>]+)>/g, (match, p1) => {
      const translated = t(p1);
      return translated === p1 ? p1 : translated;
    });
  };

  const detection = selectedChat?.detection;
  const deviceType = detection?.device_type;
  const deviceBrand = detection?.device_brand;
  const deviceModel = detection?.device_model;

  const metadata = {
    state: { ...selectedChat?.state_metadata?.state },
    current_state: selectedChat?.state_metadata?.current_state,
  };

  if (metadata.current_state && metadata.current_state.description) {
    metadata.current_state.description = processDescription(
      metadata.current_state.description,
      t
    );
  }

  if (deviceType && deviceType !== "unknown") {
    metadata["detection"] = metadata?.detection || {};
    metadata["detection"]["device_type"] = deviceType;
  }
  if (deviceBrand && deviceBrand !== "unknown") {
    metadata["detection"] = metadata?.detection || {};
    metadata["detection"]["device_brand"] = deviceBrand;
  }
  if (deviceModel && deviceModel !== "unknown" && deviceModel.length) {
    metadata["detection"] = metadata?.detection || {};
    if (deviceModel.length === 1) {
      metadata["detection"]["device_model"] = deviceModel[0];
    } else {
      metadata["detection"]["device_model"] = deviceModel;
    }
  }
  const formattedCreatedAt = selectedChat
    ? new Date(selectedChat.created_at).toLocaleString()
    : "";
  const formattedUpdatedAt = selectedChat
    ? new Date(selectedChat.updated_at).toLocaleString()
    : "";

  // Render confirmation dialog
  const renderCloseChatDialog = () => (
    <Dialog
      open={isCloseDialogOpen}
      onClose={() => setIsCloseDialogOpen(false)}
      aria-labelledby="close-chat-dialog-title"
    >
      <DialogTitle id="close-chat-dialog-title">
        {t("confirm_close_chat")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("are_you_sure_close_chat")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsCloseDialogOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          onClick={handleCloseChatConfirm}
          color="primary"
          disabled={closingChat}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderRestartChatDialog = () => (
    <Dialog
      open={isRestartDialogOpen}
      onClose={() => setIsRestartDialogOpen(false)}
      aria-labelledby="restart-chat-dialog-title"
    >
      <DialogTitle id="restart-chat-dialog-title">
        {t("confirm_restart_chat")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("are_you_sure_restart_chat")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsRestartDialogOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          onClick={handlRestartChatConfirm}
          color="primary"
          disabled={closingChat}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid
      item
      xs={rightSidebarSize[0]}
      sm={rightSidebarSize[1]}
      md={rightSidebarSize[2]}
      style={{ height: "90vh", marginTop: "2vh" }}
    >
      {loadingChat ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Sidebar
          ref={rightSidebarRef}
          isSidebarOpen={isRightSidebarOpen}
          isMobile={isMobile}
        >
          <IconButton
            onClick={toggleRightSidebar}
            style={{ float: "right", margin: "1vh" }}
          >
            <CloseIcon />
          </IconButton>
          {hasPrivilege("backoffice_chat", "edit") && (
            <Box>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
                sx={{ mb: 3, mt: 3 }}
              >
                {t("select_chat_mode")}
              </Typography>
              <CenteredModeButtons>
                <Select
                  value={chatMode}
                  onChange={handleChangeMode}
                  displayEmpty
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="ai">{t("ai_mode")}</MenuItem>
                  <MenuItem value="copilot">{t("copilot_mode")}</MenuItem>
                  <MenuItem value="human">{t("manual_mode")}</MenuItem>
                </Select>
              </CenteredModeButtons>
            </Box>
          )}

          {/* Información del chat después del selector */}
          <Typography variant="h6" sx={{ mb: 3 }}>
            {t("chat_information")}
          </Typography>
          <Divider sx={{ my: 1, mb: 3 }} />

          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
              }}
            >
              <b>{t("chat_created")}:</b> {formattedCreatedAt}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
              }}
            >
              <b>{t("chat_updated")}:</b> {formattedUpdatedAt}
            </Typography>
          </Box>
          <Box
            mt={1}
            style={{ maxHeight: "50vh", overflowY: "auto" }}
            sx={{ mb: 3 }}
          >
            {renderMetadataTable(metadata)}
          </Box>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setIsCloseDialogOpen(true)}
            fullWidth
            disabled={!selectedChat || closingChat}
            sx={{ mb: 2 }}
          >
            {t("close_chat")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsRestartDialogOpen(true)}
            fullWidth
            disabled={!selectedChat || closingChat}
            sx={{ mb: 2 }}
          >
            {t("restart_chat")}
          </Button>

          {renderCloseChatDialog()}
          {renderRestartChatDialog()}
        </Sidebar>
      )}
    </Grid>
  );
};

export default ChatInformation;
