import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getData, postData, patchData } from "../../services/Api";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from "@mui/icons-material/Comment";

import { Sidebar, ChatArea } from "./ChatsViewStyles";
import ChatList from "./ChatList";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import ChatInformation from "./ChatInformation";
import { hasPrivilege } from "../../utils/AuthUtils";
import { getChatTitle } from "../../utils/Chat";

const ChatsView = () => {
  const { t } = useTranslation("chats");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { userId, chatId: chatIdParam } = useParams();
  const [selectedState, setSelectedState] = useState("all");

  const [chats, setChats] = useState([]);
  const [loadingChats, setLoadingChats] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [chatsLimit, setChatsLimit] = useState(25);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [chatMode, setChatMode] = useState("ai");
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(true);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(!isMobile);
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [closingChat, setClosingChat] = useState(false);
  const [isRestartDialogOpen, setIsRestartDialogOpen] = useState(false);
  const [restartingChat, setRestartingChat] = useState(false);

  const chatHistoryRef = useRef(null);
  const sidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const intervalRef = useRef(null);
  const isFetchingRef = useRef(false);

  const chatIntervalRef = useRef(null);
  const isChatFetchingRef = useRef(false);
  useEffect(() => {
    const startFetchingChats = async () => {
      if (!isFetchingRef.current) {
        await fetchChats();
      }
    };

    startFetchingChats();

    // Clear the interval on unmount
    return () => clearTimeout(intervalRef.current);
  }, [selectedState, chatsLimit, lastEvaluatedKey]);

  useEffect(() => {
    if (selectedChat && chatIdParam === selectedChat.chat_id) return;
    fetchChat(chatIdParam, userId);
  }, [chatIdParam, userId]);

  const fetchChats = async () => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;
    setLoadingChats(true);

    try {
      const chatListResponse = await getData("/backoffice/chats", {
        limit: chatsLimit,
        last_evaluated_key: lastEvaluatedKey,
        state: selectedState !== "all" ? selectedState : undefined,
      });

      setChats((prevChats) => {
        const combinedChats = [...prevChats, ...chatListResponse.chats];
        const uniqueChats = Array.from(
          new Map(combinedChats.map((chat) => [chat.chat_id, chat])).values()
        );
        return uniqueChats;
      });

      setLastEvaluatedKey(chatListResponse.last_evaluated_key || null);
      setUnreadMessages((prevUnreadMessages) => {
        const updatedUnreadMessages = { ...prevUnreadMessages };

        chatListResponse.chats.forEach((chat) => {
          const currentMessageCount = chat.messages.length;
          const previousMessageCount =
            prevUnreadMessages[chat.chat_id]?.messageCount || 0;

          // If the current message count is greater than the previous one, mark as unread
          if (
            currentMessageCount > previousMessageCount &&
            (!selectedChat || selectedChat.chat_id !== chat.chat_id)
          ) {
            updatedUnreadMessages[chat.chat_id] = {
              hasNewMessages: true,
              messageCount: currentMessageCount,
            };
          } else {
            // Remove from unread if the chat is viewed or no new messages are present
            updatedUnreadMessages[chat.chat_id] = {
              hasNewMessages: false,
              messageCount: currentMessageCount,
            };
          }
        });

        return updatedUnreadMessages;
      });
    } catch (error) {
      console.error(t("error_loading_data"), error);
    } finally {
      setLoadingChats(false);
      isFetchingRef.current = false;
      // Wait 10 seconds before calling fetchChats again
      intervalRef.current = setTimeout(fetchChats, 10000);
    }
  };
  const handleLoadMore = () => {
    setChatsLimit(chatsLimit + 10);
  };

  const [currentPage, setCurrentPage] = useState(1); // Track current page

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
    setChats([]); // Reset current chat list when the state changes
    setLastEvaluatedKey(null); // Reset pagination key
  };

  const fetchChat = async (chatId, userId, spinner = true) => {
    if (!chatId || !userId) return;
    if (spinner) setLoadingChat(true);
    try {
      const response = await getData(`/backoffice/chats/${chatId}`, {
        user_id: userId,
      });
      setSelectedChat(response);
      setChatMode(response.mode);
    } catch (error) {
      console.error(t("error_loading_data"), error);
    } finally {
      if (spinner) setLoadingChat(false);
    }
  };

  useEffect(() => {
    isChatFetchingRef.current = false;
    if (chatIntervalRef.current) {
      clearTimeout(chatIntervalRef.current);
    }
    // Wait 10 seconds before calling fetchChats again
    chatIntervalRef.current = setTimeout(
      () => fetchNewMessages(chatIdParam, userId),
      2500
    );
    return () => clearTimeout(chatIntervalRef.current);
  }, [selectedChat]);

  const fetchNewMessages = async (chatId, userId) => {
    if (!chatId || !userId) return;
    if (isChatFetchingRef.current) return;
    isChatFetchingRef.current = true;

    try {
      const response = await getData(`/backoffice/chats/${chatId}`, {
        user_id: userId,
      });

      const newMessages = response.messages;
      const currentMessages = selectedChat.messages;

      const isMessagesChanged =
        newMessages.length !== currentMessages.length ||
        newMessages.some(
          (msg, index) =>
            msg.id !== currentMessages[index]?.id ||
            msg.content !== currentMessages[index]?.content
        );

      if (isMessagesChanged) {
        setSelectedChat({ ...response });
      } else {
        chatIntervalRef.current = setTimeout(
          () => fetchNewMessages(chatId, userId),
          2500
        );
      }
    } catch (error) {
      console.error(t("error_loading_new_messages"), error);
    } finally {
      isChatFetchingRef.current = false;
    }
  };

  const handleChatClick = (chat) => {
    setUnreadMessages((prevUnreadMessages) => {
      const updatedUnreadMessages = { ...prevUnreadMessages };
      delete updatedUnreadMessages[chat.chat_id];
      return updatedUnreadMessages;
    });

    if (isMobile) {
      setIsLeftSidebarOpen(false);
      setIsRightSidebarOpen(false);
    }
    navigate(`/chats/${chat.user_id}/${chat.chat_id}`);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || !selectedChat || sendingMessage) return;

    setSendingMessage(true);

    try {
      await postData(`/backoffice/chats/${selectedChat.chat_id}/messages`, {
        user_id: selectedChat.user_id,
        message: newMessage,
      });
      await fetchNewMessages(selectedChat.chat_id, selectedChat.user_id);
      setNewMessage("");
    } catch (error) {
      console.error(t("error_sending_message"), error);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleChangeMode = async (event) => {
    const newMode = event.target.value;
    setChatMode(newMode);

    if (!selectedChat) return;

    try {
      const response = await patchData(
        `/backoffice/chats/${selectedChat.chat_id}`,
        {
          mode: newMode,
          user_id: selectedChat.user_id,
        }
      );

      setSelectedChat(response);
    } catch (error) {
      console.error(t("error_changing_mode"), error);
    }
  };

  const toggleLeftSidebar = () => {
    if (!isLeftSidebarOpen && isMobile) {
      setSelectedChat(null);
    }
    setIsLeftSidebarOpen(!isLeftSidebarOpen);
  };
  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  const renderMetadataTable = (obj) => (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="metadata table">
        <TableBody>
          {Object.entries(obj).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell
                component="th"
                scope="row"
                style={{
                  fontWeight: "bold",
                  wordBreak: "break-all",
                }}
              >
                {t(key)}
              </TableCell>
              {Array.isArray(value) ? (
                <TableCell colSpan={2}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableBody>
                        {value.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                wordBreak: "break-all",
                              }}
                            >
                              {typeof item === "object" && item !== null
                                ? renderMetadataTable(item)
                                : String(item)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  {typeof value === "object" && value !== null
                    ? renderMetadataTable(value)
                    : String(value)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  let leftSidebarSize = [12, 3, 3];
  let rightSidebarSize = [12, 4, 4];
  let centerSidebarSize = [12, 5, 5];

  if (isMobile) {
    if (isLeftSidebarOpen) {
      leftSidebarSize = [12, 12, 12];
      centerSidebarSize = [0, 0, 0];
      rightSidebarSize = [0, 0, 0];
    } else if (isRightSidebarOpen) {
      rightSidebarSize = [12, 12, 12];
      centerSidebarSize = [0, 0, 0];
      leftSidebarSize = [0, 0, 0];
    } else {
      centerSidebarSize = [12, 12, 12];
      leftSidebarSize = [0, 0, 0];
      rightSidebarSize = [0, 0, 0];
    }
  } else {
    if (!isLeftSidebarOpen) {
      centerSidebarSize[1] += leftSidebarSize[1];
      centerSidebarSize[2] += leftSidebarSize[2];
      leftSidebarSize = [0, 0, 0];
    }
    if (!isRightSidebarOpen) {
      centerSidebarSize[1] += rightSidebarSize[1];
      centerSidebarSize[2] += rightSidebarSize[2];
      rightSidebarSize = [0, 0, 0];
    }
  }

  const handleCloseChatConfirm = async () => {
    if (!selectedChat) return;
    setClosingChat(true);

    try {
      await patchData(`/backoffice/chats/${selectedChat.chat_id}`, {
        user_id: selectedChat.user_id,
        state: "closed_by_operator", // setting state to closed by operator
      });
      // Optionally refresh the chat data or update UI to show the chat is closed
      fetchChat(selectedChat.chat_id, selectedChat.user_id, false);
    } catch (error) {
      console.error(t("error_closing_chat"), error);
    } finally {
      setClosingChat(false);
      setIsCloseDialogOpen(false);
    }
  };

  const handlRestartChatConfirm = async () => {
    if (!selectedChat) return;
    setRestartingChat(true);

    try {
      await patchData(`/backoffice/chats/${selectedChat.chat_id}`, {
        user_id: selectedChat.user_id,
        restart: true,
      });
      // Optionally refresh the chat data or update UI to show the chat is closed
      fetchChat(selectedChat.chat_id, selectedChat.user_id, false);
    } catch (error) {
      console.error(t("error_closing_chat"), error);
    } finally {
      setRestartingChat(false);
      setIsRestartDialogOpen(false);
    }
  };
  return (
    <Grid container>
      {leftSidebarSize.some((x) => x > 0) && (
        <Grid
          item
          xs={leftSidebarSize[0]}
          sm={leftSidebarSize[1]}
          md={leftSidebarSize[2]}
          style={{ height: "90vh", marginTop: "2vh" }}
        >
          <Sidebar
            ref={sidebarRef}
            isSidebarOpen={isLeftSidebarOpen}
            isMobile={isMobile}
          >
            <ChatList
              chats={chats}
              loadingChats={loadingChats}
              unreadMessages={unreadMessages}
              selectedChat={selectedChat}
              handleChatClick={handleChatClick}
              lastEvaluatedKey={lastEvaluatedKey}
              handleLoadMore={handleLoadMore}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              selectedState={selectedState}
              handleStateChange={handleStateChange}
            />
          </Sidebar>
        </Grid>
      )}
      {centerSidebarSize.some((x) => x > 0) && (
        <Grid
          item
          xs={centerSidebarSize[0]}
          sm={centerSidebarSize[1]}
          md={centerSidebarSize[2]}
          style={{ height: "90vh", marginTop: "2vh" }}
        >
          {loadingChat ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <ChatArea>
              <Grid
                container
                spacing={-2}
                sx={{ margin: 1 }}
                style={{ height: "5%" }}
              >
                <Grid item xs={2} sm={2} md={2}>
                  <IconButton
                    onClick={() =>
                      isRightSidebarOpen && isMobile
                        ? toggleRightSidebar()
                        : toggleLeftSidebar()
                    }
                  >
                    {isLeftSidebarOpen ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                    <CommentIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={8} sm={8} md={8} style={{ textAlign: "center" }}>
                  {selectedChat && getChatTitle(selectedChat)}
                </Grid>
                <Grid item xs={2} sm={2} md={2} style={{ textAlign: "right" }}>
                  <IconButton onClick={toggleRightSidebar}>
                    <InfoIcon />
                    {isRightSidebarOpen ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={-2} sx={{ margin: 1, height: "100%" }}>
                {selectedChat &&
                ((isMobile && !isRightSidebarOpen) || !isMobile) ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        height: hasPrivilege("backoffice_chat", "create")
                          ? "85%"
                          : "95%",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <ChatMessages
                        chatHistoryRef={chatHistoryRef}
                        messages={selectedChat?.messages || []}
                        isMobile={isMobile}
                      />
                    </Grid>
                    {hasPrivilege("backoffice_chat", "create") && (
                      <ChatInput
                        handleSendMessage={handleSendMessage}
                        selectedChat={selectedChat}
                        sendingMessage={sendingMessage}
                        newMessage={newMessage}
                        setNewMessage={setNewMessage}
                      />
                    )}
                  </>
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Typography variant="h6">
                        {t("select_chat_to_view_messages")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ChatArea>
          )}
        </Grid>
      )}
      {rightSidebarSize.some((x) => x > 0) && (
        <ChatInformation
          isRightSidebarOpen={isRightSidebarOpen}
          chatMode={chatMode}
          selectedChat={selectedChat}
          loadingChat={loadingChat}
          closingChat={closingChat}
          renderMetadataTable={renderMetadataTable}
          handleCloseChatConfirm={handleCloseChatConfirm}
          rightSidebarSize={rightSidebarSize}
          rightSidebarRef={rightSidebarRef}
          toggleRightSidebar={toggleRightSidebar}
          handleChangeMode={handleChangeMode}
          isCloseDialogOpen={isCloseDialogOpen}
          setIsCloseDialogOpen={setIsCloseDialogOpen}
          isRestartDialogOpen={isRestartDialogOpen}
          setIsRestartDialogOpen={setIsRestartDialogOpen}
          handlRestartChatConfirm={handlRestartChatConfirm}
        />
      )}
    </Grid>
  );
};

export default ChatsView;
