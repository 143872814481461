import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Select,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CommentIcon from "@mui/icons-material/Comment";
import { UnreadBadge } from "./ChatsViewStyles";
import { getChatTitle } from "../../utils/Chat";

const ITEMS_PER_PAGE = 9; // Number of chats per page

const ChatList = ({
  chats,
  loadingChats,
  unreadMessages,
  selectedChat,
  handleChatClick,
  lastEvaluatedKey,
  handleLoadMore,
  currentPage,
  setCurrentPage,
  selectedState,
  handleStateChange,
}) => {
  const { t } = useTranslation("chats");

  const getStatusColor = (state) => {
    switch (state) {
      case "active":
        return "primary"; // Blue
      case "human_required":
        return "warning"; // Purple
      case "resolved_by_user":
        return "success"; // Green
      default:
        return "default"; // Grey
    }
  };

  // Separate human_required chats from others and sort by latest message timestamp
  const humanRequiredChats = chats.filter(
    (chat) => chat.state === "human_required"
  );
  const otherChats = chats.filter((chat) => chat.state !== "human_required");

  const sortedChats = [
    ...humanRequiredChats.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    ),
    ...otherChats.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
    ),
  ];

  // Paginate sorted chats
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedChats = sortedChats.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (endIndex + 1 < sortedChats.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (lastEvaluatedKey) {
      handleLoadMore();
    }
  };

  const handleBackPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box>
      <List>
        <h2 style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
          <CommentIcon style={{ fontSize: "24px", color: "black" }} />
          {t("Conversaciones")}
        </h2>

        <FormControl fullWidth size="small">
          <InputLabel>{t("Filtrar por estado")}</InputLabel>
          <Select
            value={selectedState}
            onChange={handleStateChange}
            label={t("Filtrar por estado")}
          >
            <MenuItem value="all">{t("all")}</MenuItem>
            <MenuItem value="active">{t("active")}</MenuItem>
            <MenuItem value="human_required">{t("human_required")}</MenuItem>
            <MenuItem value="stale">{t("stale")}</MenuItem>
            <MenuItem value="resolved_by_user">
              {t("resolved_by_user")}
            </MenuItem>
            <MenuItem value="closed_by_operator">
              {t("closed_by_operator")}
            </MenuItem>
          </Select>
        </FormControl>

        {paginatedChats.length > 0 || !loadingChats ? (
          paginatedChats.map((chat) => {
            const lastMessage = chat.messages[chat.messages.length - 1] || {};
            const lastMessageText =
              lastMessage.sender === "ai"
                ? t("waiting_user_message")
                : lastMessage.message;

            const timestamp = lastMessage.timestamp
              ? new Date(lastMessage.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "";

            const chatName = getChatTitle(chat);
            const stateColor = getStatusColor(chat.state);

            const isDisabled =
              chat.human_id &&
              chat.human_id !==
                JSON.parse(localStorage.getItem("user"))["user_id"];

            return (
              <ListItem
                key={chat.chat_id}
                onClick={() => handleChatClick(chat)}
                selected={selectedChat && selectedChat.chat_id === chat.chat_id}
                sx={{
                  backgroundColor: isDisabled
                    ? "grey"
                    : unreadMessages[chat.chat_id]?.hasNewMessages
                    ? "#e3f2fd"
                    : "inherit",
                  padding: 2,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  "&:hover": {
                    backgroundColor: isDisabled ? "inherit" : "action.hover",
                  },
                  position: "relative",
                }}
              >
                <Chip
                  label={t(chat.state)}
                  color={stateColor}
                  size="small"
                  sx={{ position: "absolute", top: 8, right: 8 }}
                />

                <ListItemText
                  primary={chatName}
                  secondary={
                    <>
                      {`${timestamp}: ${lastMessageText.slice(0, 50)}...`}
                      {chat.human_id && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          display="block"
                        >
                          {t("handled_by")}: {chat.human_id}
                        </Typography>
                      )}
                    </>
                  }
                />
                {unreadMessages[chat.chat_id]?.hasNewMessages && (
                  <UnreadBadge>!</UnreadBadge>
                )}
              </ListItem>
            );
          })
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </List>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "1vh", gap: "1vw" }}
      >
        <Button
          variant="contained"
          onClick={handleBackPage}
          disabled={currentPage === 1}
        >
          {t("previous")}
        </Button>
        <Typography>{currentPage}</Typography>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={
            loadingChats ||
            (endIndex >= sortedChats.length && !lastEvaluatedKey)
          }
        >
          {loadingChats ? <CircularProgress /> : t("next")}
        </Button>
      </Box>
    </Box>
  );
};

export default ChatList;
