import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";

export const Sidebar = styled(Box)(({ theme, isSidebarOpen, isMobile }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  overflowY: "auto",
  overflowX: "hidden",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  flexShrink: 0,
  height: "100%",
  transition: "width 0.3s ease-in-out",
  padding: theme.spacing(1),
}));

export const ChatArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  height: "100%",
}));

export const ChatHistory = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  height: "100%",
  padding: theme.spacing(2),
}));

export const ChatBubble = styled(Paper)(({ theme }) => ({
  maxWidth: "75%",
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  wordWrap: "break-word",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
}));

export const ChatBubbleUser = styled(ChatBubble)(({ theme }) => ({
  alignSelf: "flex-start",
  color: theme.palette.text.primary,
  backgroundColor: "#fff",
  borderRadius: "15px 15px 0 15px",
  marginRight: "auto",
}));

export const ChatBubbleOperator = styled(ChatBubble)(({ theme }) => ({
  alignSelf: "flex-end",
  backgroundColor: "#dcf8c6",
  color: theme.palette.text.primary,
  borderRadius: "15px 15px 15px 0",
  marginLeft: "auto",
}));

export const CenteredModeButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const UnreadBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  borderRadius: "50%",
  padding: "0 6px",
  marginLeft: theme.spacing(1),
}));
